import React, { useState } from 'react';
import cn from 'classnames';
import { Redirect, Link, useParams } from 'react-router-dom';

import { useTranslation, useAppSelector, useAppDispatch, useAnalytics } from 'client/hooks';
import { companies as companiesApi } from 'client/redux/api/companies';
import { favoriteList as favoriteListApi } from 'client/redux/api/favorite-list';

import { setPopup } from 'client/redux/ui';
import { selectPopup } from 'client/redux/ui/selectors';
import { selectAuth, selectData, selectUserGAStatus } from 'client/redux/user/selectors';
import { CATEGORY_PAGE_ROUTE, COMPANY_PAGE_ROUTE, NOT_FOUND_PAGE_ROUTE } from 'client/constants/routes';
import { CONFIG } from 'constants/index';
import { imgixUrl } from 'client/utils/imgix-url';

import {
  Icon,
  RatingUpButton,
  CompaniesSlider,
  FoundersPopup,
  NewsPopup,
  OpacityTransitionComponent,
  AddYourCompanyBtnLink,
  HelmetComponent,
  CompanyInfo,
} from 'client/components/common';

import News from './News';
import Founders from './Founders';
import VideoSlider from './VideoSlider';
import LoaderSkeleton from './LoaderSkeleton';

import css from './Company.module.scss';

const IMGIX_PARAMS = {
  bluredBG: {
    'max-w': 1600,
    fit: 'fill',
    auto: 'format,compress',
    q: 40,
  },
  logo: {
    'max-w': 300,
    fit: 'fill',
    auto: 'format,compress',
    q: 85,
  },
  ogImage: {
    'max-w': 1200,
    height: 630,
    fit: 'fill',
    auto: 'format,compress',
    q: 70,
    crop: 'entropy',
  },
};

const Company = () => {
  const { translate } = useTranslation();
  const analytics = useAnalytics();
  const infoPopup = useAppSelector(selectPopup);
  const auth = useAppSelector(selectAuth);
  const userData = useAppSelector(selectData);
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const dispatch = useAppDispatch();
  const routeParams = useParams<{ id: string }>();

  const [showSlider, setShowSlider] = useState(false);

  const [addCompany, addCompanyState] = favoriteListApi.endpoints.addToFavoriteList.useMutation();
  const [deleteCompany, deleteCompanyState] = favoriteListApi.endpoints.deleteFromFavoriteList.useMutation();
  const [updateRating, updateRatingState] = companiesApi.endpoints.updateRating.useMutation();

  const { data, isLoading } = companiesApi.endpoints.getCompany.useQuery({ id: routeParams.id || '' });
  const companyInfo = data && data.success ? data.data : undefined;
  const {
    data: relatedData,
    isFetching: relatedDataIsFetching,
    isLoading: relatedDataIsLoading,
  } = companiesApi.endpoints.paginateCompanies.useQuery(
    {
      filter: {
        categoryIds: companyInfo?.categories.map((cat) => cat._id.toString()).join(),
      },
      limit: 50,
      offset: 0,
    },
    { skip: !companyInfo },
  );

  const relatedCompanies = relatedData?.success
    ? relatedData?.data.items.filter((company) => company._id !== routeParams.id)
    : undefined;

  const isAlreadyVoted = userData.voted.includes(companyInfo?._id || '');
  const isInFavoriteList = userData.favorite.includes(companyInfo?._id || '');

  React.useEffect(() => {
    if (companyInfo && userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: `Company - ${companyInfo.name}`,
        page_location: `${CONFIG.domainUrl}${COMPANY_PAGE_ROUTE.replace(':id', companyInfo._id)}`,
      });
    }
  }, [companyInfo, userGAStatus]);

  React.useEffect(() => {
    setShowSlider(true);
  }, []);

  const onUpRatingBtnClick = () => {
    if (!auth.isLoggedIn) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      return;
    }

    if (!routeParams.id) {
      return;
    }

    updateRating({ companyId: routeParams.id });
  };

  const addToMyList = () => {
    if (!auth.isLoggedIn) {
      dispatch(setPopup({ type: 'auth', data: { initialAuthType: 'sign-in' } }));
      return;
    }

    if (!routeParams.id) {
      return;
    }

    if (isInFavoriteList) {
      deleteCompany({ companyId: routeParams.id });
    } else {
      addCompany({ companyId: routeParams.id });
    }
  };

  if (companyInfo?.isVisible === false) {
    return <Redirect to={NOT_FOUND_PAGE_ROUTE} />;
  }

  return (
    <>
      <HelmetComponent
        title={`${companyInfo?.name} - Catalyst Investors' Club`}
        description={`${companyInfo?.subTitle}. See more about ${companyInfo?.name} on Catalyst Investors' Club`}
        ogTitle={`${companyInfo?.name}`}
        ogDescription={`${companyInfo?.subTitle}. See more about ${companyInfo?.name} on Catalyst Investors' Club`}
        ogImage={`${imgixUrl(companyInfo?.mainImage, IMGIX_PARAMS.ogImage)}`}
      />
      <div className={css.company}>
        {isLoading ? (
          <LoaderSkeleton />
        ) : (
          <>
            <div
              className={css.blurSection}
              style={
                companyInfo?.mainImage
                  ? { backgroundImage: `url(${imgixUrl(companyInfo?.mainImage, IMGIX_PARAMS.bluredBG)})` }
                  : {}
              }
            ></div>
            <div className={css.contentWrapper}>
              {companyInfo?.videos && (
                <VideoSlider companyName={companyInfo.name} companyId={companyInfo._id} videos={companyInfo.videos} />
              )}
              <div className={css.companyInfoWrapper}>
                <CompanyInfo className={css.companyInfo} companyInfo={companyInfo} />
                <div className={css.infoRight}>
                  <div className={css.actionBtns}>
                    <div
                      className={cn(
                        css.addToMyListBtn,
                        isInFavoriteList && css.wasAdded,
                        (addCompanyState.isLoading || deleteCompanyState.isLoading) && css.loading,
                      )}
                      onClick={addToMyList}
                    >
                      <div className={cn(css.btnInner)}>
                        <Icon type="bookmark" className={css.icon} />
                        {isInFavoriteList
                          ? translate('companyPage.wasAddedToMyList')
                          : translate('companyPage.addToMyListBtn')}
                      </div>
                      <div className={cn(css.filter)}></div>
                    </div>
                    {/* <div
                      className={cn(
                        css.upvoteBtn,
                        isAlreadyVoted && css.voted,
                        updateRatingState.isLoading && css.loading,
                      )}
                      onClick={onUpRatingBtnClick}
                    >
                      <div className={css.btnInner}>
                        {isAlreadyVoted ? translate('companyPage.votedLabel') : translate('companyPage.upvoteBtn')}

                        <div className={css.divider}></div>
                        <div className={css.rate}>
                          <span>{companyInfo?.rating}</span>
                          <RatingUpButton id={companyInfo?._id || ''} size="small" />
                        </div>
                      </div>
                      <div className={css.filter}></div>
                    </div> */}
                  </div>
                  {companyInfo?.team && companyInfo?.team.length > 0 && <Founders team={companyInfo?.team} />}
                  <div className={css.categories}>
                    <div className={css.categoriesTitle}>{translate('companyPage.categoriesTitle')}</div>
                    <div className={css.categoriesWrapper}>
                      {companyInfo?.categories.map((cat) => {
                        return (
                          <Link
                            to={CATEGORY_PAGE_ROUTE.replace(':id', cat._id.toString())}
                            className={css.category}
                            key={cat._id.toString()}
                          >
                            <div className={css.categoryInner}>{cat.name}</div>
                            <div className={css.bluredBlock}></div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {companyInfo?.news && companyInfo?.news.length > 0 && <News news={companyInfo?.news} />}
            </div>
            <AddYourCompanyBtnLink from={COMPANY_PAGE_ROUTE.replace(':id', routeParams.id)} />
            {showSlider && (
              <div className={css.sliderWrapper}>
                <CompaniesSlider
                  title={translate('companyPage.similarSliderTitle')}
                  companies={relatedCompanies}
                  isLoading={relatedDataIsLoading || relatedDataIsFetching}
                  isOnCompanyPage
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Company;
