import { CONFIG } from 'constants/index';

import aronPacificiImg from 'client/assets/about/team/aron_pacifici.jpg';
import avivMizrahiImg from 'client/assets/about/team/aviv_mizrahi.jpg';
import danaSombergImg from 'client/assets/about/team/dana_somberg.jpg';
import danielElfenImg from 'client/assets/about/team/daniel_elfen.jpg';
import edouardCukiermanImg from 'client/assets/about/team/edouard_cukierman.jpg';
import laurentChoppeImg from 'client/assets/about/team/laurent_choppe.jpg';
import lucMullerImg from 'client/assets/about/team/luc_muller.jpg';
import maorLutingerImg from 'client/assets/about/team/maor_lutinger.jpg';
import michaelCukiermanImg from 'client/assets/about/team/michael_cukierman.jpg';
import mosheGaonImg from 'client/assets/about/team/moshe_gaon.jpg';
import tomRozenvasserImg from 'client/assets/about/team/tom_rozenvasser.jpg';
import yaelRubinsonImg from 'client/assets/about/team/yael_rubinson.jpg';
import gilRabbiImg from 'client/assets/about/team/gil_rabbi.jpg';

export * as routes from './routes';

export enum CLIENT_ROLE {
  UNKNOWN = 'UNKNOWN',
  USER = 'USER',
  ARTIST = 'ARTIST',
}

export const ANONYMOUS_UID = 'anonymous';

export const ERROR = {
  REQUIRED: 'Required',
  TOO_SHORT: 'Too short',
  TOO_LONG: 'Too long',
  TOO_WEAK: 'Too weak',
  INVALID_FORMAT: 'Invalid format',
  INVALID_VALUE: 'Invalid value',
};

export const REG_EXP = {
  NUMBERS: /^[0-9\b]+$/,
  HEBREW_ONLY_LETTERS: /^[\u0590-\u05FF ,.'"-]+$/i,
  HEBREW_WITH_NUMBERS: /^[\u0590-\u05FF 0-9 ,.'"-]+$/,
  EMAIL: /[a-zA-Z@. 0-9]/,
  ENGLISH: /[a-zA-Z 0-9]/,
  WEBSITE: /http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?(\/)?/g,
  NUMBERSPLUSMINUS: /^[0-9 ()+-]+$/,
  UNICODELETTERS: /^[a-zA-Z \u0590-\u05FF '" äöüÄÖÜß]+$/i,
};

export const STORAGE_FILES = {
  termsAndConditions: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/TERMS%20OF%20USE.pdf`,
  privacyPolicy: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/PRIVACY%20STATEMENT.pdf`,
  cyberCecurity: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/INTERENT%20SECURITY.pdf`,
  disclaimers: `https://storage.googleapis.com/${CONFIG.firebase.projectId}.appspot.com/websitedocs/DISCLAIMER.pdf`,
};

export const BOARD = [
  {
    name: 'Dana Somberg',
    image: danaSombergImg,
  },
  {
    name: 'Moshe Gaon',
    image: mosheGaonImg,
  },
  {
    name: 'Laurent Choppe',
    image: laurentChoppeImg,
  },
  {
    name: 'Luc Muller',
    image: lucMullerImg,
  },
];

export const TEAM = [
  {
    name: 'Edouard Cukierman',
    position: 'Chairman & CEO',
    image: edouardCukiermanImg,
  },
  {
    name: 'Daniel Elfen',
    position: 'VP Business Development',
    image: danielElfenImg,
  },
  {
    name: 'Maor Lutinger',
    position: 'Finance Director',
    image: maorLutingerImg,
  },
  {
    name: 'Gil Rabbi',
    position: 'Technology Partner',
    image: gilRabbiImg,
  },
  {
    name: 'Aviv Mizrahi',
    position: 'Product Director',
    image: avivMizrahiImg,
  },
  {
    name: 'Tom Rozenvasser',
    position: 'Marketing Director',
    image: tomRozenvasserImg,
  },
  {
    name: 'Yael Rubinson',
    position: 'Content Manager',
    image: yaelRubinsonImg,
  },
  {
    name: 'Aron Pacifici',
    position: 'Marketing Manager',
    image: aronPacificiImg,
  },
  {
    name: 'Michael Cukierman',
    position: 'Business Operations',
    image: michaelCukiermanImg,
  },
];

export const INTERCOM_APP_ID = 'es58l7mm';
