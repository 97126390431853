import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { useAppDispatch, useTranslation } from 'client/hooks';
import * as routes from 'client/constants/routes';
import { setMenuState } from 'client/redux/ui';

import css from './ControlPanel.module.scss';

interface Props {
  className?: string;
}

function Menu(props: Props) {
  const { className = '' } = props;
  const menuItems = [
    { name: 'aboutUs', link: routes.ABOUT_PAGE_ROUTE },
    // { name: 'clubPage', link: routes.CLUB_PAGE_ROUTE },
    { name: 'faq', link: routes.FAQ_PAGE_ROUTE },
    { name: 'addYourCompany', link: routes.ADD_YOUR_COMPANY_PAGE_ROUTE },
    { name: 'contactUs', link: routes.CONTACT_US_PAGE_ROUTE },
  ];
  const policyItems = [
    {
      name: 'termsAndConditions',
      link: routes.TERMS_OF_USE_PAGE_ROUTE,
    },
    {
      name: 'privacyPolicy',
      link: routes.PRIVACY_POLICY_PAGE_ROUTE,
    },
    {
      name: 'cyberCecurity',
      link: routes.CYBER_SECURITY_PAGE_ROUTE,
    },
    {
      name: 'disclaimers',
      link: routes.DISCLAIMER_PAGE_ROUTE,
    },
    {
      name: 'accessibilityStatetment',
      link: routes.ACCESSIBILITY_STATETMENT_PAGE_ROUTE,
    },
  ];

  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const clickOnLink = () => {
    dispatch(setMenuState('hidden'));
  };

  return (
    <div className={cn(css.subContent, className)}>
      <div className={css.subContentScrollable}>
        <div className={css.subContentTitle}>Menu</div>
        {menuItems?.map((item) => (
          <NavLink
            to={item.link}
            key={item.name}
            className={(isActive) => {
              return cn(css.subContentItem, { [css.active]: isActive });
            }}
            onClick={clickOnLink}
          >
            {translate(`menu.menuItems.${item.name}`)}
          </NavLink>
        ))}
        <div className={css.subContentTitle}>Policy</div>
        {policyItems?.map((item) => (
          <NavLink to={item.link} className={css.subContentItem} onClick={clickOnLink} key={item.name}>
            {translate(`footer.menuItems.${item.name}`)}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export { Menu };
export default Menu;
