import { LOCALE } from 'client/types';

export const FAQCONTENT = [
  {
    value: [
      {
        locale: LOCALE.EN,
        question: "Who can join Catalyst Investors' Club?",
        answer: 'Membership is limited to accredited investors only, as defined by relevant securities regulations.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'What are the benefits of becoming a member?',
        answer:
          'Members gain access to exclusive investment opportunities, expert support, networking opportunities, and the potential for high returns.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'How do I become an accredited investor?',
        answer:
          'Accreditation requirements vary by jurisdiction but typically involve meeting certain income or net worth thresholds.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'Are there any fees associated with membership?',
        answer:
          'Yes, there may be membership fees and/or fees associated with specific investment opportunities. These will be clearly outlined during the application process.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'How are investment opportunities selected?',
        answer:
          'Our team conducts meticulous screening and due diligence for each opportunity, ensuring alignment with our vision and criteria for exceptional potential.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'What is the minimum investment amount?',
        answer:
          'Minimum investment amounts vary depending on the specific opportunity. Details will be provided for each investment opportunity on our platform.',
      },
    ],
  },
  {
    value: [
      {
        locale: LOCALE.EN,
        question: 'How do I stay updated on investment progress and company developments?',
        answer:
          'Members will receive regular updates and communications regarding their investments and relevant company developments through our platform and other communication channels.',
      },
    ],
  },
];
