import React from 'react';
import cn from 'classnames';

import { main as mainApi } from 'client/redux/api/main';
import { useAnalytics, useTranslation } from 'client/hooks';
import { useAppSelector } from 'client/hooks/redux';
import { selectUserGAStatus, selectUserRole } from 'client/redux/user/selectors';
import { CONFIG } from 'constants/index';
import { ROOT } from 'client/constants/routes';
import { CompaniesSlider, AddYourCompanyBtnLink } from 'client/components/common';

import TopSlider from './TopSlider';
import HeroSlider from './HeroSlider';
import BottomSliders from './BottomSliders';
// import JoinSection from './JoinSection';
import InvestNow from './InvestNow';
import FakeMandateSlider from './FakeMandatesSlides';

import css from './Main.module.scss';

interface Props {
  className?: string;
}

const Main: React.FC<Props> = (props) => {
  const { className = '' } = props;
  const userGAStatus = useAppSelector(selectUserGAStatus);
  const userRole = useAppSelector(selectUserRole);
  const analytics = useAnalytics();
  const { data, isLoading } = mainApi.endpoints.getMainPageSliders.useQuery({});
  const slidersData = data && data.success ? data.data : undefined;
  const { data: fakeSlides, isLoading: isLoadingFakeSlides } = mainApi.endpoints.getFakeSlides.useQuery({});
  const fakeSliderData = fakeSlides && fakeSlides.success ? fakeSlides.data : undefined;

  const { translate } = useTranslation();

  React.useEffect(() => {
    if (userGAStatus) {
      analytics.gtag.event('page_view', {
        page_title: 'MainPage',
        page_location: `${CONFIG.domainUrl}${ROOT}`,
      });
    }
  }, [userGAStatus]);

  return (
    <div className={cn(css.main, className)}>
      <HeroSlider items={slidersData?.heroSlider} isLoading={isLoading} />
      <TopSlider items={slidersData?.topWatchedSlider} isLoading={isLoading} />
      {/* <JoinSection /> */}
      <section className={css.featuredSlider}>
        <CompaniesSlider
          title={translate('mainPageDesktop.slidersTitles.featuredInnovation')}
          companies={slidersData?.featuredSlider}
          isLoading={isLoading}
        />
      </section>
      {userRole !== 'VIEWER' && slidersData?.investNowSlider && slidersData?.investNowSlider.length > 0 && (
        <InvestNow items={slidersData?.investNowSlider} isLoading={isLoading} />
      )}

      {userRole === 'VIEWER' && fakeSliderData && fakeSliderData.length > 0 && (
        <FakeMandateSlider items={fakeSliderData} isLoading={isLoadingFakeSlides} />
      )}

      <BottomSliders />
      <AddYourCompanyBtnLink />
    </div>
  );
};

export default Main;
